import React, { useState } from "react";
import { Card, Button, Modal, Form } from "antd";
import ProfileForm from "../../forms/profile-form/ProfileForm"; // Import ProfileForm
import "./SchoolProfile.css";

const SchoolProfile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [profile, setProfile] = useState({
    schoolName: "Hamza School",
    schoolMotto: "Your School Motto",
    established: "1990",
    type: "Public/Private",
    grades: "K-12",
    address: "1234 School St, Your City, Your State, Your Country",
    phone: "(123) 456-7890",
    email: "info@yourschool.edu",
    overview:
      "Your school description goes here. Include information about your school's history, mission, vision, and any notable achievements or programs.",
    logoURL: "../../../../images/logo.svg",
    description: "",
  });

  const showModal = () => {
    form.setFieldsValue(profile);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setProfile(values);
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div id="SchoolProfile">
      <Card className="profile-card">
        <div className="school-profile">
          <header className="school-header">
            <img
              src={profile.logoURL}
              alt="School Logo"
              className="school-logo"
            />
            <h1 className="school-name">{profile.schoolName}</h1>
            <p className="school-motto">"{profile.schoolMotto}"</p>
          </header>

          <section className="school-details">
            <h2>School Details</h2>
            <p>
              <strong>Established:</strong> {profile.established}
            </p>
            <p>
              <strong>Type:</strong> {profile.type}
            </p>
            <p>
              <strong>Grades:</strong> {profile.grades}
            </p>
          </section>

          <section className="contact-info">
            <h2>Contact Information</h2>
            <p>
              <strong>Address:</strong> {profile.address}
            </p>
            <p>
              <strong>Phone:</strong> {profile.phone}
            </p>
            <p>
              <strong>Email:</strong> {profile.email}
            </p>
          </section>

          <section className="overview">
            <h2>Overview</h2>
            <p>{profile.overview}</p>
          </section>

          <section className="social-media">
            <h2>Follow Us</h2>
            <p>
              <a
                href="https://www.facebook.com/yourschool"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>{" "}
              |
              <a
                href="https://www.twitter.com/yourschool"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>{" "}
              |
              <a
                href="https://www.instagram.com/yourschool"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </p>
          </section>
        </div>
        <Button
          type="primary"
          className="set-profile-button"
          onClick={showModal}
        >
          Set Profile
        </Button>
      </Card>

      <Modal
        title="Set Profile"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ProfileForm form={form} />
      </Modal>
    </div>
  );
};

export default SchoolProfile;
