import axios from "axios";

const baseUrl = window.location.origin;

const api = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const SchoolToken = localStorage.getItem("schoolToken");
    const userToken = localStorage.getItem("userToken");
    const adminToken = localStorage.getItem("adminToken");

    if (SchoolToken) {
      config.headers.Authorization = `Bearer ${SchoolToken}`;
    }
    if (userToken) {
      config.headers["userToken"] = userToken;
    }
    if (adminToken) {
      config.headers["adminToken"] = adminToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const postApi = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
export const getApi = async (endpoint, params = {}) => {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await api.get(`${endpoint}?${query}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const patchApi = async (endpoint, value) => {
  try {
    const response = await api.patch(endpoint, value);
    return response.data;
  } catch (error) {
    throw error;
  }
};
