import React, { useEffect, useState } from "react";
import PhoneInput from "../../phoneInput/PhoneInput";
import {
  nationalities,
  grade as grades,
  section,
  woreda,
  subCitiesByRegion,
} from "../../../services/data";
import {
  Card,
  Steps,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Row,
  Col,
  message,
  List,
  Tag,
  Modal,
} from "antd";
import CloseContactForm from "../close-contact-form/CloseContactForm";

const { Step } = Steps;
const { Option } = Select;

const StudRegForm = ({ form, current, setCurrent }) => {
  const [closeContactForm] = Form.useForm();
  const [guardian, setGuardian] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("Addis Ababa");
  const [modalClosed, setModalClosed] = useState(false);

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = guardian.filter((_, i) => i !== index);
    setGuardian(updatedContacts);
  };

  const handleAddContact = async () => {
    try {
      const values = await closeContactForm.validateFields();
      if (
        guardian.some((el) => el.closeContactPhone === values.closeContactPhone)
      )
        return message.warning("Already added!");
      setGuardian([...guardian, values]);
      closeContactForm.resetFields();
    } catch (error) {}
  };

  useEffect(() => {
    setSelectedRegion("Addis Ababa");
    const setGuardianState = async () => {
      const values = await form.getFieldValue("guardians");
      if (values?.length) {
        setGuardian(values);
      }
    };
    setGuardianState();
  }, []);

  useEffect(() => {
    if (guardian.length) {
      form.setFieldsValue({ guardians: guardian });
      setModalClosed(false);
    }
  }, [guardian]);

  const validateFullName = (_, value) => {
    // Trim the input to remove leading and trailing spaces
    const trimmedValue = value ? value.trim() : "";

    // Check if the trimmed value matches the pattern for two or more words
    if (!trimmedValue || /^[a-zA-Z]+(\s[a-zA-Z]+){1,}$/.test(trimmedValue)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        "Please enter a full name with at least two words separated by spaces"
      )
    );
  };

  const steps = [
    {
      title: "Step 1",
      fields: [
        "fullName",
        "birthYear",
        "gender",
        "phone1",
        "phone2",
        "nationality",
        "motherFullName",
        "region",
        "subCity",
      ],
      content: (
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: "Please enter your full name" },
                { validator: validateFullName },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Date of Birth(Ethiopian)"
              name="birthYear"
              rules={[
                { required: true, message: "Please select your date of birth" },
              ]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Sex"
              name="gender"
              rules={[{ required: true, message: "Please select your gender" }]}
            >
              <Select>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <PhoneInput form={form} name="phone1" label="Phone 1" />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <PhoneInput form={form} name="phone2" label="Phone 2" />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Nationality"
              name="nationality"
              initialValue="Ethiopia"
              rules={[
                { required: true, message: "Please select your nationality" },
              ]}
            >
              <Select showSearch>
                {nationalities.map((country, index) => (
                  <Option key={index} value={country}>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Mother Full Name"
              name="motherFullName"
              rules={[
                { required: true, message: "Please enter your mother's name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Region"
              name="region"
              initialValue={selectedRegion}
              rules={[{ required: true, message: "Please select your region" }]}
            >
              <Select onChange={handleRegionChange}>
                {Object.keys(subCitiesByRegion)?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Sub City"
              name="subCity"
              initialValue={subCitiesByRegion?.[selectedRegion]?.[0]}
              rules={[
                { required: true, message: "Please select your sub city" },
              ]}
            >
              <Select>
                {subCitiesByRegion[selectedRegion]?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Step 2",
      fields: [
        "wereda",
        "homeNumber",
        "grade",
        "section",
        "prevGrade",
        "prevAverage",
        "branchName",
        "branchNumber",
      ],
      content: (
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Wereda"
              name="wereda"
              initialValue={woreda[0]}
              rules={[{ required: true, message: "Please select your wereda" }]}
            >
              <Select>
                {woreda.map((woreda, index) => (
                  <Option key={index} value={woreda}>
                    {woreda}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Home Number"
              name="homeNumber"
              rules={[{ message: "Please enter your home number" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Grade"
              name="grade"
              initialValue={grades[0]?.val}
              rules={[{ required: true, message: "Please select your grade" }]}
            >
              <Select>
                {grades.map((gra) => (
                  <Option key={gra.val} value={gra.val}>
                    {gra.val}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Section"
              name="section"
              initialValue={section[0]}
              rules={[
                { required: true, message: "Please select your section" },
              ]}
            >
              <Select>
                {section.map((sec) => (
                  <Option key={sec} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Previous Grade"
              name="prevGrade"
              rules={[{ message: "Please select your previous grade" }]}
            >
              <Select>
                {grades.map((gra) => (
                  <Option key={gra.val} value={gra.val}>
                    {gra.val}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Previous Average"
              name="prevAverage"
              rules={[{ message: "Please enter your previous average" }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Branch Name"
              name="branchName"
              initialValue="Main"
              rules={[{ message: "Please enter your branch name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Branch Number"
              name="branchNumber"
              initialValue="1"
              rules={[{ message: "Please enter your branch number" }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Step 3",
      fields: ["guardians"],
      content: (
        <>
          <Row gutter={16}>
            <Modal
              title="Add Close Contact (guardian)"
              open={modalClosed}
              onOk={handleAddContact}
              style={{ maxWidth: 400 }}
              onCancel={() => setModalClosed(false)}
            >
              <CloseContactForm form={closeContactForm} />
            </Modal>
            <Col xs={24}>
              <Button
                onClick={() => setModalClosed(true)}
                style={{ marginBottom: 16 }}
              >
                Add Contact
              </Button>
              <Form.Item
                label="Guardians"
                name="guardians"
                help={
                  guardian.length === 0
                    ? "Please add at least one guardian."
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please add at least one guardian",
                  },
                ]}
              >
                <List
                  bordered
                  itemLayout="horizontal"
                  dataSource={guardian}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button
                          type="link"
                          onClick={() => handleDeleteContact(index)}
                          className="delete-btn"
                        >
                          Remove
                        </Button>,
                      ]}
                    >
                      <Tag>
                        {item.closeContactName} - {item.closeContactRelation} -{" "}
                        {item.closeContactPhone}
                      </Tag>
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleNext = async () => {
    try {
      if (current + 1 < steps.length) {
        await form.validateFields(steps[current].fields);
        setCurrent(current + 1);
      }
    } catch (error) {
      console.error("Validation Failed:", error);
    }
  };

  const handlePrevious = () => {
    if (current > 0) setCurrent(current - 1);
  };

  return (
    <div>
      <Steps current={current}>
        {steps.map((step) => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <Card style={{ marginTop: 16 }}>
        <Form form={form} layout="vertical">
          {steps[current].content}
          <Form.Item style={{ marginTop: 24 }}>
            <Button
              style={{ margin: "0 8px" }}
              onClick={handlePrevious}
              disabled={current <= 0}
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              disabled={current >= steps.length - 1}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default StudRegForm;
