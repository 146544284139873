import { useRoutes } from "react-router-dom";
import SchoolProfile from "../../components/setting-components/school-profile/SchoolProfile";
import Subject from "../../components/setting-components/subject/Subject";
import Assessment from "../../components/setting-components/assessment/Assessment";
import MarkList from "../../components/setting-components/mark-list/MarkList";
import Attendance from "../../components/setting-components/attendance/Attendance";
import DashboardMain from "../../components/dashboard-main/DashboardMain";

const AdminRoute = () => {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardMain />,
    },
    {
      path: "/profile",
      element: <SchoolProfile />,
    },
    {
      path: "/subject",
      element: <Subject />,
    },
    {
      path: "/assessment",
      element: <Assessment />,
    },
    {
      path: "/mark-list",
      element: <MarkList />,
    },
    {
      path: "/attendance",
      element: <Attendance />,
    },
    {
      path: "/*",
      element: <DashboardMain />,
    },
  ]);
  return routes;
};

export default AdminRoute;
