import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { Button, Result } from "antd";

function Error({ status, message }) {
  return (
    <Result
      status={status}
      title={status}
      subTitle={message}
      extra={
        <Button type="primary">
          <Link to="/">Back Login</Link>
        </Button>
      }
    />
  );
}

export default Error;
