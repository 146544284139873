import React, { useState, useEffect } from "react";
import "./Login.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Anchor,
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Popconfirm,
} from "antd";
import usePost from "../../hooks/usePost";
import { useAccAuth } from "../../context/UserAccountAuth";

function Login() {
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginRes, setLoginRes] = useState(null);
  const { userAuthStatus, setUserAuthStatus } = useAccAuth();

  const { post: sendLoginData, reset: resetLoginPost } = usePost(
    "/user/login",
    {
      setLoading: setLoginLoading,
      setError: setLoginError,
      setData: setLoginRes,
    }
  );

  const onFinish = (values) => {
    console.log("post data:", values);
    values.email = values?.email?.trim();
    sendLoginData(values);
  };

  useEffect(() => {
    console.log("-----------------------res", loginRes);

    if (loginError) message.error(loginError.message);
    if (loginRes?.message) message.success(loginRes?.message);

    if (loginRes?.status === "success" && loginRes?.token) {
      localStorage.setItem(`userToken`, loginRes.token);
      if (loginRes?.school?.name)
        localStorage.setItem(`schoolName`, loginRes.school.name);
      if (loginRes?.school?.detail?.logo) {
        console.log(loginRes.school.detail.logo);

        localStorage.setItem(`schoolLogo`, loginRes.school.detail.logo);
      }
      setUserAuthStatus({
        ...userAuthStatus,
        role: loginRes?.user.role,
        isAuthenticated: true,
      });
    }
    resetLoginPost();
  }, [loginRes, loginError]);
  const handleSchoolForget = () => {
    localStorage.setItem("schoolToken", null);
    setUserAuthStatus({ ...userAuthStatus, isSchoolVerified: false });
  };

  return (
    <div id="Login">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h1>Login</h1>
        <Form.Item
          name="email"
          className="form-item"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item
          name="password"
          className="form-item"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", width: "100%" }}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
            <Popconfirm
              title="Are you sure!"
              onConfirm={handleSchoolForget}
              style={{ fontSize: 10 }}
            >
              <Button>Forget School</Button>
            </Popconfirm>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loginLoading}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
