import React from "react";
import "./Card.css";

function CustomCard({ amount, icon }) {
  return (
    <section id="card">
      <div className="like-i">{icon}</div>
      <div>
        <h2>{amount}</h2>
      </div>
    </section>
  );
}

export default CustomCard;
