import React from "react";
import { noticeBoardData } from "../../services/data";
import "./NoticeBoard.css";

function NoticeBoard() {
  return (
    <div id="NoticeBoard">
      <div className="category-container">
        {noticeBoardData.map((notice, index) => {
          return (
            <div key={index}>
              <div key={index} className="dashboard-card-title">
                <h3>{notice.title}</h3>
              </div>

              {notice.detail.map((list, i) => {
                const { name, time, date, description } = list;

                const categoryColorIndex = i % 3;

                return (
                  <div key={i}>
                    <div
                      className={`category category-color-${
                        categoryColorIndex + 1
                      }`}
                    >
                      {date}
                    </div>
                    <p className="meta">{description}</p>
                    <p>
                      {name} / {time}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NoticeBoard;
