import React from "react";
import { FaBell, FaQuestion, FaUser } from "react-icons/fa";
import { Button, Layout, Menu } from "antd";
import { useAccAuth } from "../../context/UserAccountAuth";
import "./Header.css";

const { Header } = Layout;

function HeaderComp({ onBarsClick }) {
  const { userAuthStatus, setUserAuthStatus } = useAccAuth();
  const schoolName = localStorage.getItem("schoolName");
  const schoolLogo = localStorage.getItem("schoolLogo");

  const handleLogout = () => {
    localStorage.setItem("userToken", "");
    setUserAuthStatus({
      ...userAuthStatus,
      role: null,
      isAuthenticated: false,
    });
  };

  const menuItems = [
    {
      key: "1",
      icon: <FaBell />,
    },
    {
      key: "2",
      icon: <FaQuestion />,
    },
    {
      key: "SubMenu",
      icon: <FaUser />,
      children: [
        {
          key: "3",
          label: <Button onClick={handleLogout}>Logout</Button>,
        },
      ],
    },
  ];

  return (
    <Header id="Header">
      <div className="header-left">
        <div className="logo">
          <img className="logo-img" src={schoolLogo} alt="logo" />
          <span>{schoolName}</span>
        </div>
      </div>
      <Menu mode="horizontal" className="menu" items={menuItems} />
    </Header>
  );
}

export default HeaderComp;
