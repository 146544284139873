import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashScreen from "./components/splash-screen/SplashScreen";
import Registrar from "./pages/registrar/Registrar";
import Administration from "./pages/administration/Administration";
import Error from "./pages/error/Error";
import Auth from "./pages/auth/Auth";
import { useAccAuth } from "./context/UserAccountAuth";
import LandingPage from "./pages/landing-page/LandingPage";
import { message } from "antd";
import useFetch from "./hooks/useFetch";
import { useNavigate } from "react-router-dom";
function App() {
  const navigate = useNavigate();

  const [, contextHolder] = message.useMessage();
  const { userAuthStatus, setUserAuthStatus } = useAccAuth();
  const {
    dataFetched: authData,
    fetchData: authUserLogin,
    loading,
    error: authError,
  } = useFetch("/authenticate", {});
  useEffect(() => {
    authUserLogin();
  }, []);

  useEffect(() => {
    authData?.status && delete authData.status;
    setUserAuthStatus(authData);
  }, [authData]);
  useEffect(() => {}, [authError, loading]);

  useEffect(() => {
    console.log("verifying");
    if (
      userAuthStatus?.isSchoolVerified &&
      userAuthStatus?.isAuthenticated &&
      userAuthStatus?.role
    ) {
      navigate(`/${userAuthStatus?.role}`);
    } else if (!userAuthStatus?.isSchoolVerified) {
      navigate("/");
    } else if (
      userAuthStatus?.isSchoolVerified &&
      !userAuthStatus?.isAuthenticated
    ) {
      navigate("/auth/login");
    }
  }, [userAuthStatus]);

  return (
    <>
      {contextHolder}
      <div className="app">
        {loading ? (
          <SplashScreen />
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/auth/login" element={<Auth role={"login"} />} />
            <Route path="/auth/school" element={<Auth role={"school"} />} />
            <Route
              path="/admin/*"
              element={userAuthStatus?.role === "admin" && <Administration />}
            />
            <Route
              path="/registrar/*"
              element={userAuthStatus?.role === "registrar" && <Registrar />}
            />

            <Route
              path="/401"
              element={<Error status={401} message={"unauthorized access!"} />}
            />
            <Route
              path="*"
              element={<Error status={404} message={"page not found"} />}
            />
          </Routes>
        )}
      </div>
    </>
  );
}

export default App;
