import {
  FaUsers,
  FaChalkboardUser,
  FaHandsHoldingChild,
  FaMoneyBillTransfer,
  FaGauge,
  FaBook,
  FaSchool,
  FaBookOpen,
  FaClipboardUser,
  FaSheetPlastic,
  FaEye,
} from "react-icons/fa6";

import {
  HomeOutlined,
  BookOutlined,
  Attendance,
  FormOutlined,
  TableOutlined,
  ScheduleOutlined,
  TeamOutlined,
  CheckOutlined,
  CreditCardOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { FaUserCheck } from "react-icons/fa";
import { colorData } from "../utils/helper";
import { Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export const TotalSchoolData = [
  {
    name: "Students",
    amount: "15000",
    icon: <FaUsers />,
    background: "linear-gradient(135deg, #34b3a0, #00796b)", // Teal to deep green
    reflectiveOverlay:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
  },
  {
    name: "Teachers",
    amount: "2234",
    icon: <FaChalkboardUser />,
    background: "linear-gradient(135deg, #1E90FF, #0047AB)", // Bright blue to navy blue
    reflectiveOverlay:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
  },
  {
    name: "Parents",
    amount: "3452",
    icon: <FaHandsHoldingChild />,
    background: "linear-gradient(135deg, #FF6F61, #D84315)", // Coral to deep orange
    reflectiveOverlay:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
  },
  {
    name: "Earnings",
    amount: "$19873",
    icon: <FaMoneyBillTransfer />,
    background: "linear-gradient(135deg, #4CAF50, #388E3C)", // Green gradient
    reflectiveOverlay:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
  },
];

export const SidebarData = [
  {
    id: "dashboard",
    name: "Dashboard",
    icon: <FaGauge />,
    link: "Dashboard",
    submenu: [{ name: "admin", href: "admin-dashboard" }],
  },
  {
    id: "student",
    name: "Student",
    icon: <FaUsers />,
    link: "student",
    submenu: [
      { name: "All Students", href: "all-student" },
      { name: "Student Details", href: "student-detail" },
      { name: "Admission Form", href: "admission-form" },
    ],
  },
  {
    id: "teacher",
    name: "Teacher",
    icon: <FaChalkboardUser />,
    link: "teacher",
    submenu: [
      { name: "All Teachers", href: "all-teacher" },
      { name: "Teachers Details", href: "teacher-details" },
      { name: "Add Teacher", href: "add-teacher" },
    ],
  },
  {
    id: "parent",
    name: "Parent",
    icon: <FaHandsHoldingChild />,
    link: "parent",
  },
  {
    id: "library",
    name: "Library",
    icon: <FaBook />,
    link: "library",
  },
  {
    id: "class",
    name: "Class",
    icon: <FaBookOpen />,
    link: "class",
  },
  {
    id: "subject",
    name: "Subject",
    icon: <FaSchool />,
    link: "subject",
  },
  {
    id: "attendance",
    name: "Attendance",
    icon: <FaClipboardUser />,
    link: "attendance",
  },
  {
    id: "exam",
    name: "Exam",
    icon: <FaSheetPlastic />,
    link: "exam",
  },
];

export const RegistrationMenuItems = [
  {
    id: "dashboard",
    name: "Dashboard",
    icon: <DashboardOutlined />,
    link: "dashboard",
    submenu: [{ name: "Admin", href: "admin-dashboard" }],
  },
  {
    id: "registration",
    name: "Registration",
    icon: <FaUsers />,
    link: "student",
    submenu: [
      { name: "Student", href: "registrar/students" },
      { name: "Staff", href: "registrar/staff" },
    ],
  },
];

export const subject = ["english", "Amharic", "Science", "Math"];

export const AdminMenuItems = [
  {
    key: "dashboard",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    href: "admin/dashboard",
  },
  {
    key: "school-profile",
    icon: <HomeOutlined />,
    label: "School Profile",
    href: "admin/profile",
  },
  {
    key: "subject",
    icon: <BookOutlined />,
    label: "Subject",
    href: "admin/subject",
  },
  {
    key: "assessment",
    icon: <FormOutlined />,
    label: "Assessment",
    href: "admin/assessment",
  },
  {
    key: "mark-list",
    icon: <TableOutlined />,
    label: "Mark List",
    href: "admin/mark-list",
  },
  {
    key: "attendance",
    icon: <FaUserCheck />,
    label: "Attendance",
    href: "administrators/attendance",
  },
  {
    key: "time-table",
    icon: <ScheduleOutlined />,
    label: "Time Table",
    href: "administrator/time-table",
  },
  {
    key: "class",
    icon: <TeamOutlined />,
    label: "Class's",
    href: "administrator/class",
  },
  {
    key: "teachers-task",
    icon: <CheckOutlined />,
    label: "Teacher's Task",
    href: "administrator/teacher-task",
  },
  {
    key: "activation",
    icon: <CheckOutlined />,
    label: "Activation",
    href: "administrator/activation",
  },
  {
    key: "payment",
    icon: <CreditCardOutlined />,
    label: "Payment",
    href: "admin/payment",
  },
];

export const doughnutChartData = {
  chartTitle: "Student",
  type: "doughnut",
  data: [
    { name: "Male", value: 370 },
    { name: "Female", value: 421 },
  ],
  detailOptions() {
    return this.data.map((val, i) => {
      const data = val;
      data.color = colorData[i];
      return data;
    });
  },
};

export const noticeBoardData = [
  {
    title: "Notice Board",
    detail: [
      {
        name: "muaz jemal",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "sheberu Zegeye",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "meshesha asegde",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "meshesha asegde",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "meshesha asegde",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "meshesha asegde",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
      {
        name: "zeritu asegde",
        date: "16 june ,2019",
        time: "5min",
        description:
          "  Lorem ipsum dolor sit, amet consectetur adipisicing elitConsequatur, voluptatum.",
      },
    ],
  },
];

// Table standard data

export const tableHeadData = [
  {
    title: "FULL NAME",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => a.chinese - b.chinese,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "GENDER",
    dataIndex: "gender",
    key: "gender",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "PHONE",
    dataIndex: "phone",
    key: "phone",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "AGE",
    dataIndex: "age",
    key: "age",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "TYPE",
    dataIndex: "type",
    key: "type",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "PAYMENT",
    dataIndex: "payment",
    key: "payment",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "DOCUMENT STATUS",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => a.gender - b.gender,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
];

export const dataSource = [
  {
    id: 1,
    name: "lidiya Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 2,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 3,
    name: "jaffar Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 4,
    name: " Aberash jaffar",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 5,
    name: "jaffar Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 6,
    name: " Aberash jaffar",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 7,
    name: "Yoseph Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 8,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 9,
    name: "Yoseph Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 10,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 11,
    name: "tsinat Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 12,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 13,
    name: "Yoseph Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 14,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 15,
    name: "Yoseph Abera",
    phone: "+251-86-94",
    gender: "male",
    age: "20",
    type: "regular",
    payment: "paid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 16,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
  {
    id: 17,
    name: " Aberash Yoseph",
    phone: "+251-86-94",
    gender: "female",
    age: "23",
    type: "weekend",
    payment: "unpaid",
    action: <FaEye />,
    status: "pending",
  },
];

export const studentDetailData = [
  { title: "Name", detail: "Jessia Rose" },
  {
    title: "Gender",
    detail: "Female",
  },
  {
    title: "Father Name",
    detail: "Jaffar Steve",
  },
  {
    title: "Mother Name",
    detail: "Naomi Rose",
  },
  {
    title: "Date of Birth",
    detail: "07.08.2016",
  },
  {
    title: "Religion",
    detail: "Muslim",
  },
  {
    title: "Father Occupation",
    detail: "Software Developer",
  },
  {
    title: "Email",
    detail: "Jessia@gmail.com",
  },
  {
    title: "Admission Date",
    detail: "07.08.2019",
  },
  {
    title: "Class",
    detail: "2",
  },
  {
    title: "Section",
    detail: "blue",
  },
  {
    title: "Roll",
    detail: "1005",
  },
  {
    title: "Address",
    detail: "House#10, Road#6 Australia",
  },
  {
    title: "Phone",
    detail: "+ 88 98568888418",
  },
];
export const teacherDetailData = [
  { title: "Name", detail: "Jessia Rose" },
  {
    title: "Gender",
    detail: "Female",
  },
  {
    title: "Father Name",
    detail: "Jaffar Steve",
  },
  {
    title: "Mother Name",
    detail: "Naomi Rose",
  },
  {
    title: "Date of Birth",
    detail: "07.08.2016",
  },
  {
    title: "Religion",
    detail: "Muslim",
  },
  {
    title: "Father Occupation",
    detail: "Software Developer",
  },
  {
    title: "Email",
    detail: "Jessia@gmail.com",
  },
  {
    title: "Admission Date",
    detail: "07.08.2019",
  },
  {
    title: "Class",
    detail: "2",
  },
  {
    title: "Section",
    detail: "blue",
  },
  {
    title: "Roll",
    detail: "1005",
  },
  {
    title: "Address",
    detail: "House#10, Road#6 Australia",
  },
  {
    title: "Phone",
    detail: "+ 88 98568888418",
  },
];

export const studentDataHead = [
  {
    title: "Roll",
    dataIndex: "roll",
    key: "roll",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Photo",
    dataIndex: "photo",
    key: "photo",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Class",
    dataIndex: "class",
    key: "class",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Section",
    dataIndex: "section",
    key: "section",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Parent",
    dataIndex: "parent",
    key: "parent",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Date Of Birth",
    dataIndex: "birth",
    key: "birth",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
];

export const studentData = [
  {
    id: 1,
    roll: "0001",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
  {
    id: 1,
    roll: "0002",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
  {
    id: 1,
    roll: "0003",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
  {
    id: 1,
    roll: "0004",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
  {
    id: 1,
    roll: "0005",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
  {
    id: 1,
    roll: "0006",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    section: "A",
    parent: "Jack Sparrow",
    address: "TA-107 Newyork",
    birth: "02/05/2001",
    phone: "+ 123 9988568",
  },
];

export const teacherDataHead = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: {
      compare: (a, b) => a.id - b.id,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Photo",
    dataIndex: "photo",
    key: "photo",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Class",
    dataIndex: "class",
    key: "class",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Section",
    dataIndex: "section",
    key: "section",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },

  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: {
      compare: (a, b) => a.roll - b.roll,
      multiple: 3,
    },
    showOnResponse: true,
    showOnDesktop: true,
  },
];

export const teacherData = [
  {
    key: 1,
    id: "#0021",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    subject: "Math",
    section: "A",
    address: "TA-107 Newyork",

    phone: "+ 123 9988568",
    email: "lidea@gmail.com",
  },
  {
    key: 2,
    id: "#0022",
    photo: "../public/images/sample-profile.png",
    name: "lidiya Abera",
    gender: "Female",
    class: "2",
    subject: "Math",
    section: "A",
    address: "TA-107 Newyork",

    phone: "+ 123 9988568",
    email: "lidea@gmail.com",
  },
];

export const articleData = [
  {
    title: "Student Information Management",
    image: "../images/article/student-info-manag.jpg",
    description:
      "Student Information Management efficiently organizes and maintains student data. with user friendly interfaces, it simplifies tasks such as enrollment, attendance tracking and academic records, contributing to a well-organized and effective educational environment",
  },
  {
    title: "Attendance Tracking",
    image: "../../images/article/attendance-tracking.jpg",
    description:
      "Attendance Tracking automates recording for accuracy and efficiency. Utilizing methods like biometrics (fingerprint, facial recognition) and mobile apps, it simplifies the process, offering real-time data for streamlined management and improved organizational efficiency.",
  },
  {
    title: "Grade and Exam Management",
    image: "../../images/article/grade-exam-manag.jpg",
    description:
      "Grade and Exam Management automates assessment processes with customizable grading scales, secure online exams, and real-time result access. It ensures efficient analysis, providing a comprehensive solution for academic assessment and management.",
  },
  {
    title: "Communication Enhancement",
    image: "../../images/article/comunication-inha.jpg",
    description:
      " Communication Enhancement facilitates seamless interaction with integrated messaging, notifications, and reliable video conferencing. Mobile-friendly and secure, it supports collaboration, contributing to an efficient and connected environment.",
  },
  {
    title: "Timetable Management",
    image: "../../images/article/time-table.jpg",
    description:
      "Timetable Management streamlines scheduling through automated and customizable solutions. With user-friendly interfaces, real-time adjustments, and seamless integration, it ensures efficient schedule management, contributing to an organized and effective operational environment.",
  },
  {
    title: "Human Resource Management",
    image: "../../images/article/human-res.jpg",
    description:
      " Human Resource Management oversees workforce functions with efficient recruitment, employee databases, performance management, and compliance. Streamlining HR practices, it ensures an organized, compliant, and optimized workforce within the organization.",
  },
  {
    title: "Parental Engagement",
    image: "../../images/article/parental-engagement.jpg",
    description:
      " Parental Engagement strengthens school-home connections through real-time updates, event notifications, and academic progress reports. Fostering active involvement, it enhances communication between schools and parents for a collaborative and informed educational journey.",
  },
  {
    title: "Online Learning Integration",
    image: "../../images/article/online-integ.jpg",
    description:
      "Online Learning Integration seamlessly blends digital resources, interactive tools, and assessments into educational platforms. Providing flexibility and accessibility, it enhances the learning experience for both students and educators, facilitating a dynamic and engaging online educational environment.",
  },
  {
    title: "Report Generation",
    image: "../../images/article/rep-gen.jpg",
    description:
      "  Report Generation automates data analysis and presentation, delivering quick, accurate, and customizable reports. This streamlined process aids informed decision-making and promotes efficient communication within organizations, enhancing overall operational effectiveness.",
  },
];
export const subCity = [
  "Kolfe Keranio",
  "Arada",
  "Akaki Kaliti",
  "Addis Ketema",
  "Bole",
  "Gullele",
  "Kirkos",
  "Lideta",
  "Nefas Silk",
  "Yeka",
];
export const ethiopianRegions = [
  "Addis Ababa",
  "Afar",
  "Amhara",
  "Benishangul-Gumuz",
  "Dire Dawa",
  "Gambela",
  "Harari",
  "Oromia",
  "Sidama",
  "Somali",
  "Southern Nations, Nationalities, and Peoples' Region (SNNPR)",
  "Tigray",
  "Metekel",
  "Kamashi",
  "Assosa",
  "Itang",
  "Jarar",
  "Nogob",
  "Shabelle",
  "Fafan",
  "Liben",
  "Afder",
  "Jijiga",
  "Guji",
  "Bale",
  "West Arsi",
  "West Hararghe",
  "West Shewa",
  "East Shewa",
  "East Hararghe",
  "Arsi",
  "Borena",
  "Hadiya",
  "Kembata Tembaro",
  "Silte",
  "Gedeo",
  "Dawro",
  "Bench Maji",
  "South Omo",
  "Gamo Gofa",
  "Kontta",
  "Kaffa",
  "Sheka",
  "Majang",
  "Nuwer",
];
export const subCitiesByRegion = {
  "Addis Ababa": [
    "Kolfe Keranio",
    "Arada",
    "Akaki Kaliti",
    "Addis Ketema",
    "Bole",
    "Gullele",
    "Kirkos",
    "Lideta",
    "Nefas Silk",
    "Yeka",
  ],
  Afar: ["Asayita", "Dubti", "Mille", "Awash", "Semera"],
  Amhara: [
    "Bahir Dar",
    "Gondar",
    "Dessie",
    "Debre Birhan",
    "Debre Markos",
    "Woldia",
    "Kombolcha",
  ],
  "Benishangul-Gumuz": ["Assosa", "Bambasi", "Gilgil Beles", "Kamashi"],
  "Dire Dawa": ["Dire Dawa"],
  Gambela: ["Gambela", "Abobo", "Itang", "Mengeshi"],
  Harari: ["Harar"],
  Oromia: [
    "Adama",
    "Jimma",
    "Shashemene",
    "Bishoftu",
    "Ambo",
    "Nekemte",
    "Asella",
  ],
  Somali: ["Jigjiga", "Kebri Dahar", "Degehabur", "Gode", "Werder"],
  "Southern Nations, Nationalities, and Peoples' Region (SNNPR)": [
    "Hawassa",
    "Wolaita Sodo",
    "Arba Minch",
    "Hosaena",
    "Butajira",
    "Dilla",
  ],
  Tigray: ["Mekelle", "Adigrat", "Axum", "Shire", "Alamata"],
  Metekel: ["Metekel Subcity1", "Metekel Subcity2"], // Update with actual subcities
  Kamashi: ["Kamashi Subcity1", "Kamashi Subcity2"], // Update with actual subcities
  Assosa: ["Assosa Subcity1", "Assosa Subcity2"], // Update with actual subcities
  Itang: ["Itang Subcity1", "Itang Subcity2"], // Update with actual subcities
  Jarar: ["Jarar Subcity1", "Jarar Subcity2"], // Update with actual subcities
  Nogob: ["Nogob Subcity1", "Nogob Subcity2"], // Update with actual subcities
  Shabelle: ["Shabelle Subcity1", "Shabelle Subcity2"], // Update with actual subcities
  Fafan: ["Fafan Subcity1", "Fafan Subcity2"], // Update with actual subcities
  Liben: ["Liben Subcity1", "Liben Subcity2"], // Update with actual subcities
  Afder: ["Afder Subcity1", "Afder Subcity2"], // Update with actual subcities
  Jijiga: ["Jijiga Subcity1", "Jijiga Subcity2"], // Update with actual subcities
  Guji: ["Guji Subcity1", "Guji Subcity2"], // Update with actual subcities
  Bale: ["Bale Subcity1", "Bale Subcity2"], // Update with actual subcities
  "West Arsi": ["West Arsi Subcity1", "West Arsi Subcity2"], // Update with actual subcities
  "West Hararghe": ["West Hararghe Subcity1", "West Hararghe Subcity2"], // Update with actual subcities
  "West Shewa": ["West Shewa Subcity1", "West Shewa Subcity2"], // Update with actual subcities
  "East Shewa": ["East Shewa Subcity1", "East Shewa Subcity2"], // Update with actual subcities
  "East Hararghe": ["East Hararghe Subcity1", "East Hararghe Subcity2"], // Update with actual subcities
  Arsi: ["Arsi Subcity1", "Arsi Subcity2"], // Update with actual subcities
  Borena: ["Borena Subcity1", "Borena Subcity2"], // Update with actual subcities
  Hadiya: ["Hadiya Subcity1", "Hadiya Subcity2"], // Update with actual subcities
  "Kembata Tembaro": ["Kembata Tembaro Subcity1", "Kembata Tembaro Subcity2"], // Update with actual subcities
  Silte: ["Silte Subcity1", "Silte Subcity2"], // Update with actual subcities
  Sidama: ["Sidama Subcity1", "Sidama Subcity2"], // Update with actual subcities
  Gedeo: ["Gedeo Subcity1", "Gedeo Subcity2"], // Update with actual subcities
  Dawro: ["Dawro Subcity1", "Dawro Subcity2"], // Update with actual subcities
  "Bench Maji": ["Bench Maji Subcity1", "Bench Maji Subcity2"], // Update with actual subcities
  "South Omo": ["South Omo Subcity1", "South Omo Subcity2"], // Update with actual subcities
  "Gamo Gofa": ["Gamo Gofa Subcity1", "Gamo Gofa Subcity2"], // Update with actual subcities
  Kontta: ["Kontta Subcity1", "Kontta Subcity2"], // Update with actual subcities
  Kaffa: ["Kaffa Subcity1", "Kaffa Subcity2"], // Update with actual subcities
  Sheka: ["Sheka Subcity1", "Sheka Subcity2"], // Update with actual subcities
  Majang: ["Majang Subcity1", "Majang Subcity2"], // Update with actual subcities
  Nuwer: ["Nuwer Subcity1", "Nuwer Subcity2"], // Update with actual subcities
};

export const academicQualifications = [
  { label: "High School Diploma", value: "high-school" },
  { label: "Associate Degree", value: "associate" },
  { label: "Bachelor of Science (BSc)", value: "bsc" },
  { label: "Bachelor of Arts (BA)", value: "ba" },
  { label: "Master of Science (MSc)", value: "msc" },
  { label: "Master of Arts (MA)", value: "ma" },
  { label: "Doctor of Philosophy (PhD)", value: "phd" },
  { label: "Doctor of Education (EdD)", value: "edd" },
  { label: "Professional Certificate", value: "certificate" },
];

export const schoolStaff = ["teacher", "registrar", "admin"];
export const nationalities = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const grade = [
  {
    val: "Nursery",
    name: "Nursery",
  },
  {
    val: "LKG",
    name: "LKG",
  },
  {
    val: "UKG",
    name: "UKG",
  },
  {
    val: "1",
    name: "Grade 1",
  },
  {
    val: "2",
    name: "Grade 2",
  },
  {
    val: "3",
    name: "Grade 3",
  },
  {
    val: "4",
    name: "Grade 4",
  },
  {
    val: "5",
    name: "Grade 5",
  },
  {
    val: "6",
    name: "Grade 6",
  },
  {
    val: "7",
    name: "Grade 7",
  },
  {
    val: "8",
    name: "Grade 8",
  },
  {
    val: "9",
    name: "Grade 9",
  },
  {
    val: "10",
    name: "Grade 10",
  },
  {
    val: "11",
    name: "Grade 11",
  },
  {
    val: "12",
    name: "Grade 12",
  },
];

export const section = ["A", "B", "C", "D", "F", "G", "H", "I"];

export const woreda = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
];

export const subjectData = {
  Nursery: [
    {
      id: 1,
      name: "Mathematics",
      type: "acadamic",
      order: "1",
    },
    {
      id: 2,
      name: "English",
      type: "non-acadamic",
      order: "2",
    },
  ],
  LKG: [
    {
      id: 3,
      name: "Science",
      type: "acadamic",
      order: " 3",
    },
    {
      id: 4,
      name: "History",
      type: "non-acadamic",
      order: "4",
    },
  ],
  UKG: [
    {
      id: 5,
      name: "Geography",
      type: "acadamic",
      order: "5",
    },
    {
      id: 6,
      name: "Physical Education",
      type: "non-acadamic",
      order: "6",
    },
  ],
};

export const subjectColumn = (handleDelete, handleEdit) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
  },

  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Are you sure you want to delete this subject?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </span>
    ),
  },
];

export const assessmentData = {
  Nursery: [
    {
      id: 1,
      name: "Worksheet 1",
      code: "W.1",
      max: "100",
      min: "50",
      order: 1,
    },
    {
      id: 2,
      name: "Worksheet 2",
      code: "W.2",
      max: "100",
      min: "50",
      order: 2,
    },
  ],
  LKG: [
    { id: 3, name: "Test 1", max: "100", min: "40" },
    { id: 4, name: "Test 2", max: "100", min: "40" },
  ],
  UKG: [{ id: 5, name: "Final", max: "100", min: "60" }],
};

export const getAssessmentColumns = (handleDelete, handleEdit) => [
  {
    title: "Assessment",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Max Value",
    dataIndex: "max",
    key: "max",
  },
  {
    title: "Min Value",
    dataIndex: "min",
    key: "min",
  },
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Are you sure you want to delete this assessment?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </span>
    ),
  },
];
