// src/components/MarkList.js

import React, { useState, useMemo } from "react";
import { grade, section, subject } from "../../../services/data"; // Assuming section and subject data are available
import { Table, Select, Card, Row, Col, Input } from "antd";
import DynamicTable from "../../table/DynamicTable";
import "./MarkList.css";

const { Option } = Select;

// Sample data
const sampleData = [
  {
    id: "St-16-O-A-H#Bc264",
    name: "Ahafiz Ali Yusuf",
    sex: "male",
    grade: "Nursery",
    section: "A",
    subject: "Math",
    W1: 5,
    T1: 8,
    Mid: 12,
    W2: 5,
    T2: 10,
    CI_Acct: 4,
    Proj: 5,
    CH_W: 5,
    Ex_B: 14,
    Final: 19,
    Total70: 58,
    Total100: 72,
  },
  {
    id: "St-16-O-A-H#Bc268",
    name: "Abdellah Abdulatif Jemal",
    sex: "male",
    grade: "Nursery",
    section: "A",
    subject: "Math",
    W1: 5,
    T1: 8,
    Mid: 13,
    W2: 5,
    T2: 13,
    CI_Acct: 4,
    Proj: 5,
    CH_W: 4.5,
    Ex_B: 19,
    Final: 19,
    Total70: 57.5,
    Total100: 96.5,
  },
  {
    id: "St-21-1-B-H#Bc270",
    name: "Sara Ahmed",
    sex: "female",
    grade: "1",
    section: "B",
    subject: "Math",
    W1: 6,
    T1: 9,
    Mid: 15,
    W2: 7,
    T2: 12,
    CI_Acct: 5,
    Proj: 6,
    CH_W: 6,
    Ex_B: 16,
    Final: 20,
    Total70: 61,
    Total100: 80,
  },
  // Add more sample data as needed
];

const generateColumns = () => [
  {
    title: "Id",
    key: "id",
    render: (text, record) => (
      <>
        <div>ID: {record.id}</div>
        <div>Full Name: {record.name}</div>
        <div>Sex: {record.sex}</div>
      </>
    ),
  },
  {
    title: "W.1 (5%)",
    key: "W1",
    dataIndex: "W1",
  },
  {
    title: "T.1 (8%)",
    key: "T1",
    dataIndex: "T1",
  },
  {
    title: "Mid (20%)",
    key: "Mid",
    dataIndex: "Mid",
  },
  {
    title: "W.2 (5%)",
    key: "W2",
    dataIndex: "W2",
  },
  {
    title: "T.2 (8%)",
    key: "T2",
    dataIndex: "T2",
  },
  {
    title: "CI Acct. (4%)",
    key: "CI_Acct",
    dataIndex: "CI_Acct",
  },
  {
    title: "Proj. (5%)",
    key: "Proj",
    dataIndex: "Proj",
  },
  {
    title: "CH W (5%)",
    key: "CH_W",
    dataIndex: "CH_W",
  },
  {
    title: "Ex. B (8%)",
    key: "Ex_B",
    dataIndex: "Ex_B",
  },
  {
    title: "Final (20%)",
    key: "Final",
    dataIndex: "Final",
  },
  {
    title: "Total-70",
    key: "Total70",
    dataIndex: "Total70",
  },
  {
    title: "Total-100",
    key: "Total100",
    dataIndex: "Total100",
  },
];

const MarkList = () => {
  const [selectedGrade, setSelectedGrade] = useState("Nursery");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);
  const handleSubjectChange = (val) => setSelectedSubject(val);

  const columns = useMemo(() => generateColumns(), []);

  // Filter data based on selected grade, section, and subject
  const filteredData = useMemo(() => {
    return sampleData.filter((item) => {
      return (
        (!selectedGrade || item.grade === selectedGrade) &&
        (!selectedSection || item.section === selectedSection) &&
        (!selectedSubject || item.subject === selectedSubject)
      );
    });
  }, [selectedGrade, selectedSection, selectedSubject]);

  return (
    <div id="MarkList">
      <h2>Mark List</h2>
      <Card title="Mark List" bordered={false} style={{ margin: "20px" }}>
        <div className="mark-list-page">
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Select
                defaultValue={selectedGrade}
                onChange={handleGradeChange}
                style={{ width: 200 }}
              >
                {grade.map((gra, index) => (
                  <Option key={index} value={gra.val}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Section"
                onChange={handleSectionChange}
                style={{ width: 200 }}
              >
                {section.map((sec, index) => (
                  <Option key={index} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Subject"
                onChange={handleSubjectChange}
                style={{ width: 200 }}
              >
                {subject.map((sub, index) => (
                  <Option key={index} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <DynamicTable
            data={filteredData}
            customColumns={columns}
            scroll={{ x: 1500 }}
          />

          {/* <Table columns={columns} dataSource={filteredData} rowKey="id" /> */}
        </div>
      </Card>
    </div>
  );
};

export default MarkList;
