import React, { useEffect } from "react";
import { articleData } from "../../services/data";
import { Link, useNavigate } from "react-router-dom";
import "./LandingPage.css";
import { useAccAuth } from "../../context/UserAccountAuth";

function LandingPage() {
  const navigate = useNavigate();
  const { userAuthStatus, setUserAuthStatus } = useAccAuth();

  // useEffect(() => {
  //   if (userAuthStatus) {
  //     if (userAuthStatus.schoolVerified) {
  //       switch (userAuthStatus.role) {
  //         case "administration":
  //           navigate("/administration");
  //           break;
  //         case "registrar":
  //           navigate("/registrar");
  //           break;
  //       }
  //     } else {
  //       navigate("/auth");
  //     }
  //   }
  // }, [userAuthStatus]);

  const handleLoginNavigate = () => {
    // setUserAuthStatus({ ...userAuthStatus, authenticated: false, role: null });
    // localStorage.setItem("userToken", undefined);
    navigate("/auth/school");
  };

  return (
    <div id="LandingPage">
      <div className="main-nav" id="main-nav">
        <div className="container main-nav-container">
          <div className="logo-container">
            <img src="../../images/logo.svg" alt="Logo" />
            <a className="logo">
              <span className="text-secondary">TOFI</span>
            </a>
          </div>

          <div className="menu-btn-container">
            <a href="#" className="btn-menu">
              <i className="fa-solid fa-bars fa-2x"></i>
            </a>
          </div>

          <div className="main-menu">
            <ul>
              <li>
                <a href="#" className="manu-link">
                  Features
                </a>
              </li>
              <li>
                <a href="/" className="manu-link">
                  Pricing
                </a>
              </li>
            </ul>
          </div>

          <div className="auth-container">
            <ul className="auth-ul">
              <li>
                <button className="btn" onClick={handleLoginNavigate}>
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
        <nav id="side-nav" className="side-nav hidden">
          <ul className="auth-ul">
            <li>
              <a href="" className="btn btn-login">
                Log In
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="#" className="manu-link">
                Features
              </a>
            </li>
            <li>
              <a href="#" className="manu-link">
                Pricing
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <header id="showcase" className="header">
        <div className="container">
          <div className="showcase-container">
            <div className="showcase-content">
              <h1>Transforming Education</h1>
              <p className="l-text">
                Our tailored solutions are designed to bring your educational
                institution up-to-date, ensuring a seamless integration of
                modern technologies and practices.
              </p>
            </div>
          </div>
        </div>
      </header>

      <main>
        <article id="articles">
          <div className="container">
            <div className="articles-container">
              {articleData.map((article, index) => {
                const { title, image, description } = article;
                return (
                  <div key={index} className="card">
                    <div>
                      <h3>{title}</h3>
                      <img src={image} alt={title} />
                    </div>
                    <div>
                      <p>{description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </main>
      <footer id="main-footer" className="py-2">
        <div className="container footer-container">
          <div className="card">
            <div>
              <img src="../../images/logo.svg" alt="Logo" />
            </div>
            <p>
              Seamlessly streamline administrative tasks, enhance communication,
              and boost overall efficiency.
            </p>
          </div>

          <div>
            <h3>Email Tofi Technologies</h3>
            <p>
              <i className="fa-solid fa-envelope"></i>
              <a href="mailto:tofitechno@gmail.com">tofitechno@gmail.com</a>
              <br />
              <a
                href="https://t.me/tofiitech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-telegram"></i> Telegram
              </a>
              <br />
              <a href="tel:+251902077707">
                <i className="fa-solid fa-phone"></i> +251902077707
              </a>
              <br />
              <a href="tel:+251919229547">
                <i className="fa-solid fa-phone"></i> +251919229547
              </a>
              <br />
              <a href="tel:+251118327335">
                <i className="fa-solid fa-phone"></i> +251118327335
              </a>
            </p>
          </div>

          <div>
            <h3>Site Links</h3>
            <ul className="list">
              <li>
                <a href="#">Help & Support</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
          </div>

          <div>
            <h2>Join Us</h2>
            <p>Join Our Telegram Channel</p>
            <a
              href="https://t.me/tofitechno"
              target="_blank"
              className="btn"
              rel="noopener noreferrer"
            >
              Join Now
            </a>
          </div>

          <p>copyright &copy; 2023, All Right Reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
