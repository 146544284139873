// src/components/Assessment.js

import React, { useEffect, useState, useMemo } from "react";
import { grade } from "../../../services/data";
import { PlusOutlined } from "@ant-design/icons";

import {
  Table,
  Button,
  Select,
  Popconfirm,
  message,
  Modal,
  Form,
  Input,
  Card,
  Row,
  Col,
} from "antd";
import "./Assessment.css";
import AssessmentForm from "../../forms/assessment-form/AssessmentForm";
import DynamicTable from "../../table/DynamicTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks//usePatch";
import useDelete from "../../../hooks/useDelete";
const { Option } = Select;

const Assessment = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [messageApi, contextHolder] = message.useMessage();

  //
  const [selectedGrade, setSelectedGrade] = useState("");
  const [editingAssessment, setEditingAssessment] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  //post
  const [assCreatedRes, setAssCreatedRes] = useState(false);
  const [assCreateLoading, setAssCreateLoading] = useState(false);
  const [assCreateError, setAssCreateError] = useState(false);
  //patch
  const [assModifyRes, setAssModifyRes] = useState(false);
  const [assModifyLoading, setAssModifyLoading] = useState(false);
  const [assModifyError, setAssModifyError] = useState(false);
  //delete
  const [assDeleteLoading, setAssDeleteLoading] = useState(false);
  const [assDeleteError, setAssDeleteError] = useState(false);
  const [assDeleteRes, setAssDeleteRes] = useState(false);
  //crud
  const {
    dataFetched: assessments = [],
    loading: assLoading,
    error: fetchAssError,
    fetchData: fetchAss,
  } = useFetch("/api/assessment", { grade: selectedGrade });

  const { post: createAssess, reset: resetCreateAssPost } = usePost(
    "/api/assessment",
    {
      setLoading: setAssCreateLoading,
      setError: setAssCreateError,
      setData: setAssCreatedRes,
    }
  );

  const { patch: modifyAssess, reset: resetAssModify } = usePatch(
    `/api/assessment/${editingAssessment?.id}`,
    {
      setLoading: setAssModifyLoading,
      setError: setAssModifyError,
      setData: setAssModifyRes,
    }
  );
  const { remove: removeAssess, reset: resetAssRemove } = useDelete(
    `/api/assessment`,
    {
      setLoading: setAssDeleteLoading,
      setError: setAssDeleteError,
      setData: setAssDeleteRes,
    }
  );

  //
  // const showLoadingToast = () => {
  //   messageApi.loading();
  // };

  ////+++++++++++added
  useEffect(() => {
    setSelectedGrade(grade[0].val);
  }, []);

  useEffect(() => {
    if (selectedGrade) fetchAss();
  }, [selectedGrade]);
  //+++++++++++++++++++

  const handleGradeChange = (val) => {
    console.log("---------val", val);

    setSelectedGrade(val);
  };
  const handleDelete = async (id) => {
    try {
      const [filteredData] = assessments.filter((val) => val.id == id);
      await removeAssess(filteredData.id);
    } catch (error) {
      console.error(error);
      message.error("Failed to delete Assessment");
    }
  };

  const handleEdit = (value) => {
    setEditingAssessment(value);
    setIsEditModalVisible(true);
  };

  const handleUpdate = async () => {
    try {
      console.log("---------selectedGrade", selectedGrade);
      form.setFieldValue("grade", selectedGrade);
      await form.validateFields();
      const values = form.getFieldValue();
      console.log(values);
      await modifyAssess(values);
    } catch (error) {
      console.error(error);
      message.error("Failed to update assessment");
    }
  };

  const handleCancelUpdate = () => {
    setIsEditModalVisible(false);
  };

  const handleCreate = async () => {
    try {
      console.log("selectedGrade create", selectedGrade);

      form.setFieldValue("grade", selectedGrade);
      await form.validateFields();
      const values = form.getFieldValue();

      await createAssess(values);
    } catch (error) {
      console.error("err---------------", error);

      message.error("Failed to create assessment");
    }
  };

  useEffect(() => {
    if (assCreateError) {
      messageApi.warning(assCreateError);
      setAssCreateError(null);
    }
  }, [assCreateError]);

  const [form] = Form.useForm();

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  useEffect(() => {
    if (assCreatedRes?.message && assCreatedRes?.status)
      message?.[assCreatedRes.status](assCreatedRes.message);
    if (assCreateError?.message) message.error(assCreateError.message);
    if (assCreatedRes?.message === "created") {
      fetchAss();
      setIsAddModalVisible(false);
    }
    resetCreateAssPost();
  }, [assCreatedRes, assCreateError]);

  useEffect(() => {
    if (assModifyRes?.message && assModifyRes?.status)
      message?.[assModifyRes.status](assModifyRes.message);
    if (assModifyError?.message) message.error(assModifyError.message);
    if (assModifyRes?.message === "updated") {
      fetchAss();
      setIsEditModalVisible(false);
    }
    resetAssModify();
  }, [assModifyRes, assModifyError]);
  useEffect(() => {
    if (assDeleteRes?.message && assDeleteRes?.status)
      message?.[assDeleteRes.status](assDeleteRes.message);
    if (assDeleteError?.message) message.error(assDeleteError.message);
    if (assDeleteRes?.message === "deleted") {
      fetchAss();
      setIsEditModalVisible(false);
    }
    resetCreateAssPost();
  }, [assDeleteRes, assDeleteError]);
  const tableActions = [
    {
      label: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      label: <DeleteOutlined />,
      onClick: (record) => handleDelete(record.id),
      confirm: true,
      confirmMessage: "Are you sure you want to delete this assessment?",
      danger: true,
    },
  ];
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "Id",
        key: "id",
        render: (text, record) => (
          <>
            <div>ID: {record.id}</div>
            <div>Name: {record.name}</div>
            <div>Code: {record.code}</div>
          </>
        ),
      },
    ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter((key) => !["id"].includes(key));
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0).toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title: key,
      key,
      dataIndex: key,
      //   key === "createdAt" || key === "updatedAt"
      //     ? editCols(key)
      //     : key.charAt(0).toUpperCase() + key.slice(1),
      // key,
      // dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    });

    return [...commonColumns, ...dynamicColumns];
  };
  const customColumns = useMemo(
    () => generateColumns(assessments),
    [assessments]
  );

  const filteredData = useMemo(() => {
    return assessments?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [assessments, searchText, selectedColumn]);

  return (
    <div id="Assessment">
      <h2>Assessment</h2>
      <Card title="Assessment Data" bordered={false} style={{ margin: "20px" }}>
        <div className="assessment-page">
          {contextHolder}

          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Input
                placeholder="Search"
                value={searchText}
                onChange={handleSearchChange}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Select
                placeholder="Select Column"
                value={selectedColumn}
                onChange={handleColumnChange}
                style={{ width: 200 }}
                allowClear
              >
                {customColumns.map((col) => {
                  return (
                    col.key !== "actions" && (
                      <Option key={col.key} value={col.key}>
                        {col.title}
                      </Option>
                    )
                  );
                })}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedGrade}
                onChange={handleGradeChange}
                style={{ marginBottom: 20 }}
              >
                {grade.map((gra, index) => (
                  <Option key={index} value={gra.val}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col flex="auto">
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAddModalVisible(true)}
                >
                  Add Assessment
                </Button>
              </div>
            </Col>
          </Row>

          <DynamicTable
            data={filteredData}
            // tableActions={tableActions}
            loading={assLoading}
            customColumns={customColumns}
            // scroll={{ x: 1500 }}
          />

          {editingAssessment && (
            <Modal
              title="Edit Assessment"
              open={isEditModalVisible}
              onOk={handleUpdate}
              onCancel={handleCancelUpdate}
            >
              <AssessmentForm
                form={form}
                editingAssessment={editingAssessment}
              />
            </Modal>
          )}

          <Modal
            title="Add Assessment"
            open={isAddModalVisible}
            onOk={handleCreate}
            loading={assCreateLoading}
            onCancel={() => setIsAddModalVisible(false)}
          >
            <AssessmentForm form={form} editingAssessment={editingAssessment} />
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default Assessment;
