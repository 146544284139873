import React, { useEffect, useState } from "react";
import { Form, Input, Select, Tag } from "antd";
function SubjectForm({ editingSubject, form }) {
  const [calcMin, setCalcMin] = useState(0);

  useEffect(() => {
    // Dynamically set form values
    editingSubject && form.resetFields();
    form.setFieldsValue(editingSubject);
  }, [form]);

  const handleFormChange = async (e) => {
    const maxVal = form.getFieldValue("max");

    if (form.getFieldValue("max") && form.getFieldValue("min")) {
      setCalcMin(Math.round(maxVal * (form.getFieldValue("min") / 100)));
      form.setFieldValue("minPercentage", calcMin);
    }
  };

  return (
    <Form
      onChange={handleFormChange}
      form={form}
      layout="vertical"
      initialValues={{ type: "academic" }}
    >
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please select the subject type!" }]}
      >
        <Select>
          <option value="academic">Academic</option>
          <option value="non academic">Non Academic</option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Subject Name"
        name="name"
        rules={[{ required: true, message: "Please input the subject name!" }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}

export default SubjectForm;
