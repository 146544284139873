import { Link, useLocation } from "react-router-dom";
import "./BreadCrumbs.css";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const pathNameMapping = {
    "student-detail": "Student Details",
    home: "Home",
    "admission-form": "Student Admit Form",
    "teacher-details": "Teacher Detail",
    "add-teacher": "Add New Teacher",
  };

  return (
    <nav>
      <ul className="breadcrumb">
        <li>
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const displayName = pathNameMapping[value] || value;

          return (
            <li key={to}>
              <Link className="link" to={to}>
                {displayName}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
