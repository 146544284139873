import React, { useState } from "react";
import { Menu, Layout } from "antd";
import SiderHeader from "../../sider-header/SiderHeader";
import { RegistrationMenuItems } from "../../../services/data";
import { Link } from "react-router-dom";
const { Sider } = Layout;

function RegistrationSider() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const items = RegistrationMenuItems.map((menuItem) => ({
    key: menuItem.id,
    icon: menuItem.icon,
    label: menuItem.name,
    children: menuItem.submenu.map((subItem) => ({
      key: subItem.href,
      label: <Link to={`/${subItem.href}`}>{subItem.name}</Link>,
    })),
  }));

  return (
    <Sider
      className="sider-comp"
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth="0"
    >
      <SiderHeader />
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
    </Sider>
  );
}

export default RegistrationSider;
