import React from "react";
import "./SplashScreen.css";

const SplashScreen = () => {
  return (
    <div className="splashScreen">
      <h1>TOFI TECHNOLOGIES</h1>
      <p>School Management System</p>
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default SplashScreen;
