export function generateRandomHexColors(numColors) {
  const colorList = [];
  for (let i = 0; i < numColors; i++) {
    let hexCode = "#";
    // Generate 6 random hexadecimal digits
    for (let j = 0; j < 6; j++) {
      hexCode += Math.floor(Math.random() * 16).toString(16);
    }
    colorList.push(hexCode);
  }
  return colorList;
}

export const colorData = ["#059bff", "#ff4069", "#059bff", "#ff4069"];

export const handleFilterNumber = (e) => {
  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
  }
};

export const convertToDisplayDate = (date) => {
  const dateObj = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(dateObj);
};
