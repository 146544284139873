import React, { useEffect, useState } from "react";
import { Form, Input, Select, Tag } from "antd";
import { handleFilterNumber } from "../../../utils/helper.js";
function ProfileForm({ form }) {
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="schoolName"
        label="School Name"
        rules={[{ required: true, message: "Please input the school name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="schoolMotto"
        label="School Motto"
        rules={[{ required: true, message: "Please input the school motto!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="established"
        label="Established"
        rules={[
          {
            required: true,
            message: "Please input the year of establishment!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: "Please input the school type!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="grades"
        label="Grades"
        rules={[{ required: true, message: "Please input the grades!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="address"
        label="Address"
        rules={[{ required: true, message: "Please input the address!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: "Please input the phone number!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Please input the email!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="overview"
        label="Overview"
        rules={[{ required: true, message: "Please input the overview!" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name="logoURL"
        label="Logo URL"
        rules={[{ required: true, message: "Please input the logo URL!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Please input the description!" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
    </Form>
  );
}

// <Form layout="vertical">
// <Form.Item label="Assessment Name">
//   <Input
//     name="name"
//     value={editingAssessment.name}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// <Form.Item label="Max Value">
//   <Input
//     name="max"
//     value={editingAssessment.max}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// <Form.Item label="Min Value">
//   <Input
//     name="min"
//     value={editingAssessment.min}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// </Form>

export default ProfileForm;
