import React, { useState } from "react";
import { Menu, Layout } from "antd";
import SiderHeader from "../../sider-header/SiderHeader";
import { AdminMenuItems } from "../../../services/data";
import { Link } from "react-router-dom";
const { Sider } = Layout;

function AdminSider() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const items = AdminMenuItems.map((menuItem, i) => ({
    key: i,
    icon: menuItem.icon,
    label: <Link to={`/${menuItem.href}`}>{menuItem.label}</Link>,
  }));

  return (
    <Sider
      className="sider-comp"
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth="0"
    >
      <SiderHeader />
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
    </Sider>
  );
}

export default AdminSider;
