import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  Button,
  Select,
  Popconfirm,
  message,
  Modal,
  Form,
  Input,
  Row,
  Col,
} from "antd";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Breadcrumb from "../../../pages/bread-crumbs/BreadCrumbs";
import DynamicTable from "../../table/DynamicTable";
import StudRegForm from "../../forms/stud-reg-form/StudRegForm";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import "./StudentRegistration.css";
import { grade } from "../../../services/data";
import { convertToDisplayDate } from "../../../utils/helper";
const { Option } = Select;

const StudentRegistration = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [modalClosed, setModalClosed] = useState(true);
  const [current, setCurrent] = useState(0);
  const [modalOkBtnEnabled, setModalOkBtnEnabled] = useState(true);
  const [selectedGrade, setSelectedGrade] = useState("Nursery");

  const [form] = Form.useForm();

  // State variables for student creation and deletion
  const [studCreateLoading, setStudCreateLoading] = useState(false);
  const [studCreateError, setStudCreateError] = useState(null);
  const [studCreatedRes, setStudCreatedRes] = useState(null);

  // API hooks
  const { post: createStud, reset: resetCreateStud } = usePost("/api/student", {
    setLoading: setStudCreateLoading,
    setError: setStudCreateError,
    setData: setStudCreatedRes,
  });

  const {
    dataFetched: StudData = [], // Ensure default is an empty array
    loading: fetchStudLoading,
    fetchData: fetchStudent,
  } = useFetch("/api/student", { grade: selectedGrade });

  useEffect(() => {
    fetchStudent();
  }, [selectedGrade]);

  useEffect(() => {
    setModalOkBtnEnabled(current + 1 < 3);
  }, [current]);

  useEffect(() => {
    if (studCreatedRes?.message && studCreatedRes?.status)
      message?.[studCreatedRes.status](studCreatedRes.message);
    if (studCreateError)
      message.error(studCreateError?.message || "System error");
    if (studCreatedRes?.message === "created") {
      fetchStudent();
      setModalClosed(true);
    }
    resetCreateStud();
  }, [studCreatedRes, studCreateError]);

  const handleCreate = async () => {
    try {
      if (current + 1 >= 3) {
        await form.validateFields();
        const values = form.getFieldValue();
        console.log(values);
        createStud(values);
      }
    } catch (error) {
      message.error("system Error");
      console.error("Validation Failed:", error);
    }
  };

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const excludedCols = [
    "ROLL NUM",
    "STUD ID",
    "FULL NAME",
    "SEX",
    "REGION",
    "SUB CITY",
    "MOTHER NAME",
    "AGE",
    "SECTION",
    "PHONE",
    "PHONE HOME",
    "CREATED",
    "UPDATED",
    "CREATED BY",
  ];
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["FULL NAME"].toUpperCase()}
            </div>
            <div className="tableSubCols details">
              Role Num: {record["ROLL NUM"]}
            </div>
            <div className="tableSubCols details">ID: {record["STUD ID"]}</div>
            <div className="tableSubCols details">Sex: {record["SEX"]}</div>
          </> //0911429007
        ),
      },
      {
        title: "PHONE",
        key: "phone",
        render: (text, record) => (
          <>
            <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
            <div className="tableSubCols details">
              Home: {record["PHONE HOME"]}
            </div>
          </>
        ),
      },
      {
        title: "SECTION",
        key: "section",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["SECTION"]}</div>
          </>
        ),
      },
      {
        title: "REGION",
        key: "region",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["REGION"]}</div>
            <div className="tableSubCols details">
              Sub City: {record["SUB CITY"]}
            </div>
            <div className="tableSubCols details">
              Mother: {record["MOTHER NAME"]}
            </div>
          </>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {convertToDisplayDate(record["CREATED"])}
            </div>
            <div className="tableSubCols details">
              Updated: {convertToDisplayDate(record["UPDATED"])}
            </div>
            <div className="tableSubCols details">
              Created by: {record["CREATED BY"]}
            </div>
          </>
        ),
      },
    ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !excludedCols.includes(key)
    );

    const dynamicColumns = keys.map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
        </span>
      ),
    });

    return [...commonColumns, ...dynamicColumns];
  };

  const customColumns = useMemo(() => generateColumns(StudData), [StudData]);

  const filteredData = useMemo(() => {
    if (!StudData) return []; // Handle null case
    return StudData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [StudData, searchText, selectedColumn]);

  const handleEdit = (record) => {
    // Define how to handle editing a student
    console.log("Editing student:", record);
  };

  const gradeOptions = grade
    .filter((col) => col.key !== "actions")
    .map((col) => ({
      key: col.val,
      value: col.val,
    }));

  const columnOptions = excludedCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="StudentRegistration">
      <div className="header">
        <h2>Students</h2>
        <Breadcrumb />
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              placeholder="grade"
              value={selectedGrade}
              onChange={(val) => setSelectedGrade(val)}
              style={{ width: 200 }}
              allowClear
              options={gradeOptions}
            />
          </Col>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
              options={columnOptions}
            />
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalClosed(false)}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
        <DynamicTable
          data={filteredData}
          tableActions={[]}
          loading={fetchStudLoading}
          customColumns={customColumns}
          scroll={{ x: 900 }}
        />
        <Modal
          title="Add Student"
          open={!modalClosed}
          onOk={handleCreate}
          okButtonProps={{ disabled: modalOkBtnEnabled }}
          style={{ minWidth: "85%" }}
          loading={studCreateLoading}
          onClose={() => setModalClosed(true)}
          onCancel={() => setModalClosed(true)}
        >
          <StudRegForm form={form} current={current} setCurrent={setCurrent} />
        </Modal>
      </div>
    </div>
  );
};

export default StudentRegistration;
