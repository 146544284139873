import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // Import the default export
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Calender.css";
import { Card } from "antd";

function Calender() {
  const [events, setEvents] = useState([
    { title: "Event 1", date: new Date(2024, 5, 7) }, // Replace with your events
  ]);
  return (
    <Card bordered={false}>
      <div className="dashboard-content">
        <div className="dashboard-title">
          <h3>Calender</h3>
        </div>
        <div className="calender">
          {/* {" "}
                  <Calendar onChange={onChange} value={date} /> */}
          <FullCalendar
            plugins={[dayGridPlugin]} // Add dayGrid plugin for basic view
            initialView="dayGridMonth" // Set initial view (dayGridMonth)
            events={events} // Pass events array
            eventClick={(event) => {
              // Handle event click (optional)
              console.log("Event clicked:", event.event);
            }}
          />
        </div>
      </div>
    </Card>
  );
}

export default Calender;
