import { useRoutes } from "react-router-dom";
import StudentRegistration from "../../components/registration-form-container/student-registration/StudentRegistration";
import StaffRegistration from "../../components/registration-form-container/staff-registration/StaffRegistration";

const RegistrarRoutes = () => {
  const routes = useRoutes([
    { path: "/students", element: <StudentRegistration /> },
    {
      path: "/staff",
      element: <StaffRegistration />,
    },
    {
      path: "/*",
      element: <StudentRegistration />,
    },
  ]);

  return routes;
};

export default RegistrarRoutes;
