import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Login from "../../components/login/Login";
import { Button } from "antd";
import SchoolCode from "../../components/school-auth/SchoolAuth";
import { FaCheck } from "react-icons/fa6";
import "./Auth.css";
import { useAccAuth } from "../../context/UserAccountAuth";
function Auth({ role }) {
  const { userAuthStatus } = useAccAuth();
  if (userAuthStatus?.isSchoolVerified) role = "login";

  //If an authenticated user went to change the school to go SchoolAuth Page
  return (
    <>
      <div id="Auth">
        <div className="main-nav" id="main-nav">
          <div className="container main-nav-container">
            <div className="logo-container">
              <img src="../../../logo.svg" alt="" />
              <a className="logo">
                <span className="text-primary">TOFI</span>
              </a>
            </div>
          </div>
        </div>
        <header id="showcase" className="showcase header">
          <div className="container">
            <div className="showcase-container">
              <div className="showcase-content">
                <h1>Integrated School Management Hub</h1>
                <p>
                  <FaCheck /> Execute tasks seamlessly within the browser and
                  native apps. <br />
                  <FaCheck /> Collaborate instantly through live, multiplayer
                  features. <br />
                  <FaCheck /> Utilize AI to enhance overall school management
                  efficiency. <br />
                  <FaCheck /> Join an active network of engaged school
                  professionals.
                </p>
              </div>
              <div className="login-form">
                {role === "school" && <SchoolCode />}
                {role === "login" && <Login />}
                {/* {userAuthStatus?.schoolVerified ? <Login /> : <SchoolCode />} */}
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Auth;
