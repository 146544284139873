import React from "react";

export default function SiderHeader() {
  const schoolName = localStorage.getItem("schoolName");
  const schoolLogo = localStorage.getItem("schoolLogo");

  return (
    <div className="logo" style={{ height: 65 }}>
      <img className="logo-img" src={schoolLogo} alt="logo" />
      <span>{schoolName}</span>
    </div>
  );
}
