import React, { useEffect, useRef } from "react";
import "./Chart.css";
import { Card } from "antd";

import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components
ChartJS.register(ArcElement, DoughnutController, Tooltip, Legend);

const Chart = ({ chartData }) => {
  const chartOptions = {
    type: chartData.type,
    data: {
      labels: [],
      datasets: [
        {
          label: "",
          data: chartData.data.map((val) => val.value),
          backgroundColor: chartData.detailOptions().map((col) => col.color),
          borderWidth: 1,
        },
      ],
    },
    options: {
      cutout: "70%",
      radius: "70%",
    },
  };

  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const chartEl = chartRef.current.getContext("2d");

    // Destroy existing Chart instance if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new ChartJS(chartEl, chartOptions);
    // Cleanup function to destroy Chart instance when component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);
  return (
    <Card bordered={false}>
      <div className="chart-title">
        <h3>{chartData.chartTitle}</h3>
      </div>
      <div className="chart-content">
        <canvas ref={chartRef} className="students-chart"></canvas>
        <Card>
          {chartData.detailOptions().map((chad, i) => {
            return (
              <div className="description" key={i}>
                <h1 style={{ color: chad.color }} className="">
                  {chad.name} :
                  <span className="male-sex-count sex-count">
                    {" "}
                    {chad.value}
                  </span>
                </h1>
              </div>
            );
          })}
        </Card>
      </div>
    </Card>
  );
};

export default Chart;
