import { Form, Input, Select } from "antd";
import React from "react";
import PhoneInput from "../../phoneInput/PhoneInput";
const { Option } = Select;
export default function CloseContactForm({ form }) {
  const guardianRelations = [
    "Father",
    "Mother",
    "Uncle",
    "Aunt",
    "Grandfather",
    "Grandmother",
    "Brother",
    "Sister",
    "Guardian",
  ];

  return (
    <Form layout="vertical" style={{ maxWidth: 400 }} form={form}>
      <Form.Item
        label="Close Contact Name"
        name="closeContactName"
        rules={[
          {
            required: true,
            message: "Please enter your close contact name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Close Contact Relation"
        name="closeContactRelation"
        rules={[
          {
            required: true,
            message: "Please enter your close contact relation",
          },
        ]}
      >
        <Select showSearch>
          {guardianRelations.map((relation, index) => (
            <Option key={index} value={relation}>
              {relation}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <PhoneInput
        form={form}
        label={"Close Contact Phone"}
        name={"closeContactPhone"}
      />
    </Form>
  );
}
