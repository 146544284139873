import { deleteApi } from "../services/api";

const useDelete = (endpoint, prop) => {
  const remove = async (id) => {
    try {
      prop.setLoading(true);
      const result = await deleteApi(`${endpoint}/${id}`);
      prop?.setData(result);
      prop.setLoading(false);
    } catch (err) {
      prop.setLoading(false);

      if (err?.response) {
        const errRes = await err.response.data;
        console.log(errRes);

        return prop?.setError(errRes);
      }
      console.error(err);
      if (err.name === "TypeError" || err.name === "SyntaxError")
        err.message = "System Error";
      prop?.setError(err);
    } finally {
      prop.setLoading(false);
    }
  };

  const reset = () => {
    prop.setLoading(false);
    prop.setData(null);
    prop?.setError(false);
  };

  return { reset, remove };
};

export default useDelete;
