import React, { useEffect, useState } from "react";
import { Form, Input, Select, Tag } from "antd";
import { handleFilterNumber } from "../../../utils/helper.js";
function AssessmentForm({ editingAssessment, form }) {
  const [calcMin, setCalcMin] = useState(0);

  useEffect(() => {
    // Dynamically set form values
    console.log(editingAssessment);
    editingAssessment && form.resetFields();
    form.setFieldsValue(editingAssessment);
  }, [form]);

  const handleFormChange = async (e) => {
    const maxVal = form.getFieldValue("max");

    if (form.getFieldValue("max") && form.getFieldValue("min")) {
      setCalcMin(Math.round(maxVal * (form.getFieldValue("min") / 100)));
      form.setFieldValue("minPercentage", calcMin);
    }
  };

  return (
    <Form
      onChange={handleFormChange}
      form={form}
      layout="vertical"
      initialValues={{ field: "academic" }}
    >
      <Form.Item
        label="Field"
        name="field"
        rules={[
          { required: true, message: "Please select the assessment field!" },
        ]}
      >
        <Select>
          <option value="academic">Academic</option>
          <option value="non academic">Non Academic</option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Assessment Name"
        name="name"
        rules={[
          { required: true, message: "Please input the assessment name!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Assessment Code"
        name="code"
        rules={[
          { required: true, message: "Please input the assessment code!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Max Value"
        name="max"
        onKeyPress={handleFilterNumber}
        rules={[
          { required: true, message: "Please input the max value!" },
          {
            type: "number",
            message: "Max value must be a number!",
            transform: (value) => Number(value),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Min Value in %"
        name="min"
        onKeyPress={handleFilterNumber}
        rules={[
          { required: true, message: "Please input the min value!" },
          {
            type: "number",
            message: "Min value must be a number!",
            transform: (value) => Number(value),
          },
          { min: 0, max: 100, message: "Min value must be between 0 and 100!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Order"
        name="order"
        onKeyPress={handleFilterNumber}
        rules={[
          { required: true, message: "Please input the order!" },
          {
            type: "number",
            message: "Order must be a number!",
            transform: (value) => Number(value),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Tag color="#2db7f5">Min Value: {calcMin}</Tag>
    </Form>
  );
}

// <Form layout="vertical">
// <Form.Item label="Assessment Name">
//   <Input
//     name="name"
//     value={editingAssessment.name}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// <Form.Item label="Max Value">
//   <Input
//     name="max"
//     value={editingAssessment.max}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// <Form.Item label="Min Value">
//   <Input
//     name="min"
//     value={editingAssessment.min}
//     onChange={handleEditInputChange}
//   />
// </Form.Item>
// </Form>

export default AssessmentForm;
