import React, { useState } from "react";
import { theme, Layout } from "antd";
import AdminRoute from "../router/AdminRoute";
import "./Administration.css";
import Headers from "../../components/header/Header";
import AdminSider from "../../components/sidebar/setting-sidebar/AdminSider";
const { Content } = Layout;

const Administration = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <AdminSider />

      <Layout
        style={{
          padding: "0 24px 24px",
        }}
      >
        <Headers />

        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: "95vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            marginTop: "30px",
          }}
        >
          <AdminRoute />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Administration;
