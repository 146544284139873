import React, { useState } from "react";
import {
  woreda,
  subCitiesByRegion,
  nationalities,
  schoolStaff,
} from "../../../services/data";
import { academicQualifications } from "../../../services/data";
import { Form, Input, Select, DatePicker, Row, Col, Card } from "antd";
import PhoneInput from "../../phoneInput/PhoneInput";

const { Option } = Select;

function StaffRegForm({ form, editingStaff }) {
  const [selectedRegion, setSelectedRegion] = useState("Addis Ababa");

  const validateFullName = (_, value) => {
    // Trim the input to remove leading and trailing spaces
    const trimmedValue = value ? value.trim() : "";

    // Check if the trimmed value matches the pattern for two or more words
    if (!trimmedValue || /^[a-zA-Z]+(\s[a-zA-Z]+){1,}$/.test(trimmedValue)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        "Please enter a full name with at least two words separated by spaces"
      )
    );
  };

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password"));
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject(
        new Error("Password must include a special character")
      );
    }
    return Promise.resolve();
  };
  return (
    <div>
      {" "}
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Please enter full name",
                },
                {
                  validator: validateFullName,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <PhoneInput form={form} name="phone" label="Phone" />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Sex"
              name="gender"
              rules={[{ required: true, message: "Please select the sex" }]}
            >
              <Select placeholder="Please select sex">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              name="educationalLevel"
              label="Educational Level"
              rules={[
                {
                  required: true,
                  message: "Please select the educational level",
                },
              ]}
            >
              <Select
                placeholder="Select Educational Level"
                options={academicQualifications}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please select role" }]}
            >
              <Select placeholder="Please select role">
                {schoolStaff.map((staff) => (
                  <Option key={staff} value={staff}>
                    {staff}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Region"
              name="region"
              initialValue={selectedRegion}
              rules={[{ required: true, message: "Please select your region" }]}
            >
              <Select onChange={handleRegionChange}>
                {Object.keys(subCitiesByRegion)?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Sub City"
              name="subCity"
              initialValue={subCitiesByRegion?.[selectedRegion]?.[0]}
              rules={[
                { required: true, message: "Please select your sub city" },
              ]}
            >
              <Select>
                {subCitiesByRegion[selectedRegion]?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Woreda"
              name="woreda"
              rules={[{ required: true, message: "Please select your woreda" }]}
            >
              <Select placeholder="Please select your woreda">
                {woreda.map((wor, index) => (
                  <Option key={index} value={wor}>
                    {wor}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Nationality"
              name="nationality"
              initialValue="Ethiopia"
              rules={[
                { required: true, message: "Please select your nationality" },
              ]}
            >
              <Select showSearch>
                {nationalities.map((country, index) => (
                  <Option key={index} value={country}>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter the email" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {!editingStaff && (
          <Row gutter={16}>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter a password" },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Confirm your Password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
}

export default StaffRegForm;
