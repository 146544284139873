import { useState, useCallback } from "react";
import { getApi } from "../services/api";

const useFetch = (endpoint, initialParams = {}) => {
  const [dataFetched, setDataFetched] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (params = initialParams) => {
      try {
        setLoading(true);
        setError(null);
        const result = await getApi(endpoint, params);
        console.log(endpoint, params, result);
        if (result) setDataFetched(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [endpoint, initialParams]
  );

  return { dataFetched, loading, error, redirect, fetchData };
};

export default useFetch;
